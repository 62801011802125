import React from 'react';
import { BrowserRouter as Router, Link, Route, Switch } from 'react-router-dom';
import Home from './Pages/Home';
import About from './Pages/About';
// import Shop from './Pages/Shop';
import Contact from './Pages/Contact';
// import Detail from './Pages/Detail';
import Card from './Pages/Card';
import Checkout from './Pages/Checkout';
import Checkout2 from './Pages/checkout2';
import Categories from './Pages/Categories';
import Categories1 from './Pages/Categories1';
// import Thankyou from './Pages/Thankyou';
import Header from './Components/Header';
import Footer from './Components/Footer';

// import demo from './Pages/demo';
// import CategoriesShop from './Pages/CategoriesShop';
import SearchData from './Pages/SearchData';

// import Signup from './Pages/Admin/Signup';
// import EditCard from "./Pages/EditCart"
// import CategoriesBook from './Pages/CategoriesBook';
// import CategoriesBags from './Pages/CategoriesBags';
// import CategoriesShoes from './Pages/CategoriesShoes';
// import ShoesProduct from './Pages/ShoesProduct';
// import ShoesProductDetails from './Pages/ShoesProductDetails';
// import SchoolClassProduct from './Pages/SchoolClassProduct';
// import BagsClassProduct from './Pages/BagsClassProduct';
// import chat from './Pages/chat';
// import SignupOtp from './Pages/SignupOtp';


// import whatapps from './assets/images/what.png';






// import SubCategories from './Pages/Categories';
// import SubCategoriesProduct from './Pages/SubCategoriesProduct';




import Bullet from './Pages/Bullet';
import Luxury from './Pages/Luxury';
import Standard from './Pages/Standard';
import SUV from './Pages/SUV';
import Budget from './Pages/Budget';
import Coasters from './Pages/Coasters';
import Monthly from './Pages/Monthly';








import Privacy from './Pages/Privacy';
import consign from './Pages/consign';
// import Shipping from './Pages/Shipping';
import tracking from './Pages/tracking';
import FAQ from './Pages/FAQ';
import Shop from './Pages/Shop';
// import SaleSubCategoriesProduct from './Pages/SaleSubCategoriesProduct';
// import menSubCategoriesProduct from './Pages/menSubCategoriesProduct';
// import womenSubCategoriesProduct from './Pages/womenSubCategoriesProduct';
import SubCategoriesProduct from './Pages/SubCategoriesProduct';
import SubCategoriesProductDetails from './Pages/SubCategoriesProductDetails';
import SubCategoriesProductDetails1 from './Pages/SubCategoriesProductDetails1';
import SubCategoriesProductDetails2 from './Pages/SubCategoriesProductDetails2';
import SizeProductDetail from './Pages/SizeProductDetail';
import thankyou from './Pages/Thankyou';
import chat from './Pages/chat';
// import WomenOnlySubCategoriesProduct from './Pages/WomenOnlySubCategoriesProduct';
// import MenOnlySubCategoriesProduct from './Pages/MenOnlySubCategoriesProduct';
import SubCategoriesProduct1 from './Pages/SubCategoriesProduct1';
import SubCategoriesProduct2 from './Pages/SubCategoriesProduct2';
import Favorite from './Pages/Favorite';
import whislist from './Pages/whislist';
// import CategoriesUniform from './Pages/CategoriesUniform';
// import UniformClassProduct from './Pages/UniformClassProduct';
// import UniformProductDetails from './Pages/UniformProductDetails';
// import SchoolBookProductDetail from './Pages/SchoolBookProductDetail';
// import BagsProductDetail from './Pages/BagsProductDetail';
// import ArrivalCategoriesProduct from './Pages/ArrivalCategoriesProduct';

import whatapps from './assets/images/what.png';
import whatapps1 from './assets/images/png.png';

// import teacherform from './Pages/teacherform';
// import studentform from './Pages/studentform';
import Shipping from './Pages/Shipping';
import Term from './Pages/Term';
import ab from './Pages/ab';
import expertpick from './Pages/expertpick';
import recommendedproduct from './Pages/recommendedproduct';
import newArrival from './Pages/newArrival';
import Error from './Pages/Error';
import Tourism from './Pages/Tourism';
import CorporateEquiries from './Pages/CorporateEquiries';
// import submain from './Pages/submain';
import rentacarkarachi from './Pages/rentacarkarachi';
import hotelinkarachi from './Pages/hotelinkarachi';
import rentacarAbbottabad from './Pages/rentacarAbbottabad';
import rentacarFaisalabad from './Pages/rentacarFaisalabad';
import rentacarGujranwala from './Pages/rentacarGujranwala';
import rentacarIslamabad from './Pages/rentacarIslamabad';
import rentacarJhelum from './Pages/rentacarJhelum';
import rentacarLahore from './Pages/rentacarLahore';
import rentacarMultan from './Pages/rentacarMultan';
import rentacarPeshawar from './Pages/rentacarPeshawar';
import rentacarSahiwal from './Pages/rentacarSahiwal';
import rentacarGujrat from './Pages/rentacarGujrat';
import rentacarNawabshah from './Pages/rentacarNawabshah';
import rentacarHyderabad from './Pages/rentacarHyderabad';
import rentacarSialkot from './Pages/rentacarSialkot';
import HotelDetail from './Pages/HotelDetail';
// import onewaydropIslamabad from './Pages/onewaydropIslamabad';
// import onewaydropmultan from './Pages/onewaydropmultan';
// import onewaydroplahore from './Pages/onewaydroplahore';
// import onewaydropfaisalabad from './Pages/onewaydropfaisalabad';
// import Privacy from './Pages/Privacy';
// import MainUserSignup from './Pages/MainUserSignup';
// import MainUserLogin from './Pages/MainUserLogin';
// import forgetpassaccount from './Pages/forgetpassaccount';
import carrentalkarachi from './Pages/carrentalkarachi';



import rent1 from './Pages/rent1';
import rent2 from './Pages/rent2';
import rent3 from './Pages/rent3';
import rent4 from './Pages/rent4';
import rent5 from './Pages/rent5';
import rent6 from './Pages/rent6';
import rent7 from './Pages/rent7';
import rent8 from './Pages/rent8';
import rent9 from './Pages/rent9';
import rent10 from './Pages/rent10';
import rent111 from './Pages/rent11';


import rent11 from './Pages/rent11 (1)';
import rent12 from './Pages/rent11 (2)';
import rent13 from './Pages/rent11 (3)';
import rent14 from './Pages/rent11 (4)';
import rent15 from './Pages/rent11 (5)';
import rent16 from './Pages/rent11 (6)';
import rent17 from './Pages/rent11 (7)';
import rent18 from './Pages/rent11 (8)';
import rent19 from './Pages/rent11 (9)';


import rentcar0 from './Pages/rentcars (0)';
import rentcar1 from './Pages/rentcars (1)';
import rentcar2 from './Pages/rentcars (2)';
import rentcar3 from './Pages/rentcars (3)';
import rentcar4 from './Pages/rentcars (4)';
import rentcar5 from './Pages/rentcars (5)';
import rentcar6 from './Pages/rentcars (6)';
import rentcar7 from './Pages/rentcars (7)';
import rentcar8 from './Pages/rentcars (8)';
import rentcar9 from './Pages/rentcars (9)';
import rentcar10 from './Pages/rentcars (10)';
import rentcar11 from './Pages/rentcars (11)';
import rentcar12 from './Pages/rentcars (12)';
import rentcar13 from './Pages/rentcars (13)';
import rentcar14 from './Pages/rentcars (14)';
import rentcar15 from './Pages/rentcars (15)';
import rentcar16 from './Pages/rentcars (16)';
import rentcar17 from './Pages/rentcars (17)';
import rentcar18 from './Pages/rentcars (18)';
import rentcar19 from './Pages/rentcars (19)';
import rentcar20 from './Pages/rentcars (20)';
import rentcar21 from './Pages/rentcars (21)';
import rentcar22 from './Pages/rentcars (22)';
import rentcar23 from './Pages/rentcars (23)';
import rentcar24 from './Pages/rentcars (24)';
import rentcar25 from './Pages/rentcars (25)';



import rentacarAirport from './Pages/rentacarAirport-Pick-&-Drop';
import rentacarBahria from './Pages/rentacarBahria-Town-Karachi';
import rentacarBaldia from './Pages/rentacarBaldia-Town';
import rentacarBufferzone from './Pages/rentacarBufferzone';
import rentacarKarachiPakistan from './Pages/rentacarKarachi-Pakistan';
import rentacarKorangiIndustrial from './Pages/rentacarKorangi Industrial Area';
import rentacarLandhiIndustrial from './Pages/rentacarLandhi-Industrial-Area';
import rentacarMalirCantt from './Pages/rentacarMalir Cantt';
import rentacarSindhiMuslim from './Pages/rentacarSindhi-Muslim-Society';
import rentacarTariqRoad from './Pages/rentacarTariq-Road';
import rentacarWedding from './Pages/rentacarWedding-Rent-A-Car';

import rentacarLarkana from './Pages/rentacarLarkana';
import rentacarGulshaneHadeed from './Pages/rentacarGulshaneHadeed';
import rentacarGulshaneMaymar from './Pages/rentacarGulshaneMaymar';
import rentacarRahimYaarKhan from './Pages/rentacarRahim Yaar Khan';
import rentacarSukkur from './Pages/rentacarSukkur';
import rentacarDefence from './Pages/rentacarDefence';
import rentacarPakistan from './Pages/rentacarPakistan';
import rentacarDadu from './Pages/rentacarDadu';
import rentacarShahdadkot from './Pages/rentacarShahdadkot';
import rentacarClifton from './Pages/rentacarClifton';
import rentacarPortQasim from './Pages/rentacarPort Qasim';
import rentacarNizamabad from './Pages/rentacarNizamabad';
import rentacarNorthNizamabad from './Pages/rentacarNorth-Nizamabad';
import rentacarGulshaneIqbal from './Pages/rentacarGulshan-e-Iqbal';
import rentacarGulistaneJohar from './Pages/rentacarGulistan-e-Johar';

import rentacarmontly from './Pages/montly';






import Companyquery from './Pages/company';


// import rentacarAirport from './Pages/rentacarAirport-Pick-&-Drop';
// import rentacarBahria from './Pages/rentacarBahria-Town-Karachi';
// import rentacarBaldia from './Pages/rentacarBaldia-Town';
// import rentacarBufferzone from './Pages/rentacarBufferzone';
// import rentacarKarachiPakistan from './Pages/rentacarKarachi-Pakistan';
// import rentacarKorangiIndustrial from './Pages/rentacarKorangi Industrial Area';
// import rentacarLandhiIndustrial from './Pages/rentacarLandhi-Industrial-Area';
// import rentacarMalirCantt from './Pages/rentacarMalir Cantt';
// import rentacarSindhiMuslim from './Pages/rentacarSindhi-Muslim-Society';
// import rentacarTariqRoad from './Pages/rentacarTariq-Road';
// import rentacarWedding from './Pages/rentacarWedding-Rent-A-Car';
// import carrentalkarachi from './Pages/carrentalkarachi';



import Abc from './Pages/abc';


// import submain from './Pages/submain';
import OnewaydropIslamabad from './Pages/onewaydropIslamabad';
import Onewaydropfaisalabad from './Pages/onewaydropfaisalabad';
import Onewaydroplahore from './Pages/onewaydroplahore';
import Onewaydropmultan from './Pages/onewaydropmultan';
import OneWay from './Pages/OneWay';
import Client from './Pages/Client';
import Sitemap from './Pages/sitemap';
import Blog from './Pages/blog';
import BlogDetail from './Pages/blogDetail';




// import help from './Pages/help';
// import userDashboard from './Pages/userDashboard';
// import EditNameAddress from './Pages/EditNameAddress';
// import userDashOrderDetails from './Pages/userDashOrderDetails';


// import SchoolCategories from './Pages/SchoolCategories';
// import SchoolClassesCategories from './Pages/SchoolClassesCategories';
// import SchoolClassesCategoriesProduct from './Pages/SchoolClassesCategoriesProduct';
// import SchoolClassesCategoriesProductDetail from './Pages/SchoolClassesCategoriesProductDetail';


// import $ from 'jquery';


// import "https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.10.0/css/all.min.css";
import "./bootstrap.min.css"
import "./all.css"
import "./custom.css"
import "./bootsnav.css"
import "./responsive.css"
import "./style1.css"
import "./css/style.css";
import "./lib/owlcarousel/assets/owl.carousel.min.css";

import "./css1/custom.css";
import "./css1/responsive.css";
import "./css1/bootstrap-select.css";
import "./css1/baguetteBox.min.css";
import "./css1/owl.carousel.min.css";
import "./css1/bootsnav.css";
import "./css1/code_animate.css";
import "./css1/carousel-ticker.css";
import "./css1/superslides.css";
import "./css1/all.css";
import "./css1/style.css";

import "./css1/bootsnav";

import "./asset1/vendor/line-awesome/line-awesome/line-awesome/css/line-awesome.min.css";
import "./asset1/css/bootstrap.min.css";
// import "./asset1/css/plugins/owl-carousel/owl.carousel.css";
import "./asset1/css/plugins/jquery.countdown.css";
import "./asset1/css/plugins/magnific-popup/magnific-popup.css";
import "./asset1/css/style.css";
import "./asset1/css/skins/skin-demo-26.css";
import "./asset1/css/demos/demo-26.css";





function App() {

  return (
    <div className="App" >                    
     <a href="https://wa.me/923332221927" target="_blank"><img src={whatapps} alt="" style={{position : "fixed" ,zIndex : "999999", top : "80%" , right : "10px",width:"70px",height : "70px"}}/></a>
     <a href="tel:+923332221927" target="_blank"><img src={whatapps1} alt="" style={{position : "fixed" ,zIndex : "999999", top : "75%" , right : "20px",width:"50px",height : "50px"}}/></a>

      <Router>

        <Header />

        <Switch>
          <Route exact path='/' component={Home} />
          <Route exact path='/term-policy' component={Term} />
          <Route exact path='/Blogs' component={Blog} />
          <Route exact path='/blog/:rentacarDetail' component={BlogDetail} />
          <Route exact path='/Shipping' component={Shipping} />
          <Route exact path='/company-query' component={Companyquery} />
          <Route exact path='/Corporate-Equiries' component={CorporateEquiries} />
          <Route exact path='/tourism' component={Tourism} />
          <Route exact path='/product/new-arrival' component={newArrival} />
          <Route exact path='/product/recommended-product' component={recommendedproduct} />
          <Route exact path='/product/expert-pick' component={expertpick} />

          <Route exact path='/shop-all' component={ab} />
          <Route exact path='/privacy' component={Privacy} />
          <Route exact path='/clients' component={Client} />
          <Route exact path='/sitemap' component={Sitemap} />
          <Route exact path='/favorite' component={Favorite} />
          <Route exact path='/whislist' component={whislist} />
          <Route exact path='/thankyou' component={thankyou} />
          <Route exact path='/consign' component={consign} />
          <Route exact path='/checkout' component={Checkout} />
          <Route exact path='/checkout2' component={Checkout2} />
          <Route exact path='/FAQ' component={FAQ} />
          <Route exact path='/chat' component={chat} />
          <Route exact path='/cart' component={Card} />
          <Route exact path='/all-shop' component={Shop} />
          <Route exact path='/contact' component={Contact} />
          <Route exact path='/about' component={About} />
          <Route exact path='/track' component={tracking} />
          <Route exact path='/a' component={Abc} />
          <Route exact path='/search/product-item' component={SearchData} />

          <Route exact path='/bullet-proof-vehicles-at-car-rentals-karachi-pakistan' component={rentcar0} />
          <Route exact path='/rent-a-car-karachi-car-rental-service-with-driver-pakistan' component={rent11} />
<Route exact path='/rent-a-car-rates-in-karachi' component={rent12} />
<Route exact path='/cheap-car-hire-in-karachi-pakistan' component={rent13} />
<Route exact path='/rent-car-services-in-karachi' component={rent14} />
<Route exact path='/rent-a-car-in-lahore-islamabad-karachi' component={rent15} />
<Route exact path='/providing-quality-car-rental-services' component={rent16} />
<Route exact path='/revo-car-rental-in-karachi' component={rent17} />
<Route exact path='/best-car-rental-rates-karachi' component={rent18} />
<Route exact path='/karachi-rent-a-car-home-car' component={rent19} />
<Route exact path='/rent-a-car-in-nooriabad' component={rent111} />


<Route exact path='/rent-a-land-cruiser-v8-in-islamabad-rawalpindi' component={rentcar1} />
<Route exact path='/audi-a3-for-rent-in-islamabad-rawalpindi-best-rates' component={rentcar2} />
<Route exact path='/rent-a-audi-a5-search-a-car-car-rental-services' component={rentcar3} />
<Route exact path='/royal-rent-a-car-in-karachi-3000prk-per-day' component={rentcar4} />
<Route exact path='/karachi-tours-rent-a-car-gulshan-e-iqbal' component={rentcar5} />
<Route exact path='/rent-a-car-dha-rent-a-car-lahore-hire-luxury-car-rental' component={rentcar6} />
<Route exact path='/can-i-rent-a-car-for-just-a-few-hours' component={rentcar7} />
<Route exact path='/car-rental-rent-a-car-quickly-and-easy-in-karachi' component={rentcar8} />
<Route exact path='/hourly-car-rental-choose-the-rate-thats-right-for-you' component={rentcar9} />
<Route exact path='/book-hourly-car-rental-save-upto-200-off-in-karachi' component={rentcar10} />
<Route exact path='/are-you-looking-for-monthly-car-for-rent-in-islamabad' component={rentcar11} />
<Route exact path='/rent-a-car-karachi-provides-an-expert-vehicle-maintenance-team' component={rentcar12} />
<Route exact path='/rent-a-car-rates-in-karachi-with-driver-online-car-rental' component={rentcar13} />
<Route exact path='/karachi-super-rent-a-car-all-over-pakistan' component={rentcar14} />
<Route exact path='/wedding-car-rental-in-karachi' component={rentcar15} />
<Route exact path='/rent-a-car-for-wedding-car-rental-services' component={rentcar16} />
<Route exact path='/wedding-rent-a-car-car-rental-pakistan' component={rentcar17} />
<Route exact path='/best-rent-a-car-karachi-book-car-for-any-travel-purpose' component={rentcar18} />
<Route exact path='/cheap-premium-car-rental-in-karachi' component={rentcar19} />
<Route exact path='/luxury-and-prestige-car-rental-in-karachi' component={rentcar20} />
<Route exact path='/rent-a-car-karachi-luxury-car-rental-services' component={rentcar21} />
<Route exact path='/premium-car-rental-karachi-top-deals-worldwide' component={rentcar22} />
<Route exact path='/searching-for-a-car-to-rent-with-no-deposit-near-you' component={rentcar23} />
<Route exact path='/book-hassle-free-car-rentals' component={rentcar24} />
<Route exact path='/get-your-next-car-booking-with-pak-dream-at-500-pkr-off' component={rentcar25} />



          <Route exact path='/car-rental-in-karachi-pakistan' component={carrentalkarachi} />

          <Route exact path='/rent-a-car-in-Airport-Pick-&-Drop' component={rentacarAirport} />
          <Route exact path='/rent-a-car-in-Wedding-Rent-A-Car' component={rentacarWedding} />
          <Route exact path='/rent-a-car-in-Karachi-Pakistan' component={rentacarKarachiPakistan} />
          <Route exact path='/rent-a-car-in-Bufferzone' component={rentacarBufferzone} />
          <Route exact path='/rent-a-car-in-Tariq-Road' component={rentacarTariqRoad} />
          <Route exact path='/rent-a-car-in-Baldia-Town' component={rentacarBaldia} />
          <Route exact path='/rent-a-car-in-Sindhi-Muslim-Society' component={rentacarSindhiMuslim} />
          <Route exact path='/rent-a-car-in-Malir-Cantt' component={rentacarMalirCantt} />
          <Route exact path='/rent-a-car-in-Korangi-Industrial-Area' component={rentacarKorangiIndustrial} />
          <Route exact path='/rent-a-car-in-Bahria-Town-Karachi' component={rentacarBahria} />
          <Route exact path='/rent-a-car-in-Landhi-Industrial-Area' component={rentacarLandhiIndustrial} />
          

          <Route exact path='/hotel-in-karachi' component={hotelinkarachi} />
          {/* <Route exact path='/one-way-drop-in-karachi-to-islamabad' component={onewaydropIslamabad} />
          <Route exact path='/one-way-drop-in-karachi-to-multan' component={onewaydropmultan} />
          <Route exact path='/one-way-drop-in-karachi-to-lahore' component={onewaydroplahore} />
          <Route exact path='/one-way-drop-in-karachi-to-faisalabad' component={onewaydropfaisalabad} />*/}
          
            <Route exact path='/one-way-drop-in-karachi-to-islamabad' component={OnewaydropIslamabad} />
            <Route exact path='/one-way-drop-in-karachi-to-multan' component={Onewaydropmultan} />
            <Route exact path='/one-way-drop-in-karachi-to-lahore' component={Onewaydroplahore} />
            <Route exact path='/one-way-drop-in-karachi-to-faisalabad' component={Onewaydropfaisalabad} />



            <Route exact path='/rent-a-car-in-badshahi-mosque-lahore' component={rent1} />
            <Route exact path='/rent-a-car-in-shalimar-bagh-lahore' component={rent2} />
            <Route exact path='/rent-a-car-in-tomb-of-jahangir-lahore' component={rent3} />
            <Route exact path='/rent-a-car-in-minar-e-pakistan-lahore' component={rent4} />
            <Route exact path='/rent-a-car-in-lahore-museum' component={rent5} />
            <Route exact path='/rent-a-car-in-wazir-khan-mosque-lahore' component={rent6} />
            <Route exact path='/rent-a-car-in-delhi-gate-lahore' component={rent7} />
            <Route exact path='/rent-a-car-in-anarkali-bazar-lahore' component={rent8} />
            <Route exact path='/rent-a-car-in-liberty-market-lahore' component={rent9} />
            <Route exact path='/rent-a-car-in-pearl-continental-hotel-lahore' component={rent10} />





          <Route exact path='/bullet-cars' component={Bullet} />
          <Route exact path='/luxury-cars' component={Luxury} />
          <Route exact path='/standard-cars' component={Standard} />
          <Route exact path='/suv-cars' component={SUV} />
          <Route exact path='/budget-cars' component={Budget} />
          <Route exact path='/coasters-cars' component={Coasters} />
          <Route exact path='/one-way-cars' component={OneWay} />
          <Route exact path='/monthly-cars' component={Monthly} />












          <Route exact path='/rent-a-car-in-Larkana' component={rentacarLarkana} />
          <Route exact path='/rent-a-car-in-Gulshan-e-Hadeed' component={rentacarGulshaneHadeed} />
          <Route exact path='/rent-a-car-in-Gulshan-e-Maymar' component={rentacarGulshaneMaymar} />
          <Route exact path='/rent-a-car-in-Rahim-Yaar-Khan' component={rentacarRahimYaarKhan} />
          <Route exact path='/rent-a-car-in-Defence' component={rentacarDefence} />
          <Route exact path='/rent-a-car-in-Pakistan' component={rentacarPakistan} />
          <Route exact path='/rent-a-car-in-Dadu' component={rentacarDadu} />
          <Route exact path='/rent-a-car-in-Shahdadkot' component={rentacarShahdadkot} />
          <Route exact path='/rent-a-car-in-Clifton' component={rentacarClifton} />
          <Route exact path='/rent-a-car-in-Port-Qasim' component={rentacarPortQasim} />
          <Route exact path='/rent-a-car-in-Nizamabad' component={rentacarNizamabad} />
          <Route exact path='/rent-a-car-in-North-Nizamabad' component={rentacarNorthNizamabad} />
          <Route exact path='/rent-a-car-in-Gulshan-e-Iqbal' component={rentacarGulshaneIqbal} />
          <Route exact path='/rent-a-car-in-Gulistan-e-Johar' component={rentacarGulistaneJohar} />
          <Route exact path='/rent-a-car-in-karachi' component={rentacarkarachi} />
          <Route exact path='/rent-a-car-in-Abbottabad' component={rentacarAbbottabad} />
          <Route exact path='/rent-a-car-in-Faisalabad' component={rentacarFaisalabad} />
          <Route exact path='/rent-a-car-in-Gujranwala' component={rentacarGujranwala} />
          <Route exact path='/rent-a-car-in-Islamabad' component={rentacarIslamabad} />
          <Route exact path='/rent-a-car-in-Jhelum' component={rentacarJhelum} />
          <Route exact path='/rent-a-car-in-Hyderabad' component={rentacarHyderabad} />
          <Route exact path='/rent-a-car-in-Nawabshah' component={rentacarNawabshah} />
          <Route exact path='/rent-a-car-in-Lahore' component={rentacarLahore} />
          <Route exact path='/rent-a-car-in-Multan' component={rentacarMultan} />
          <Route exact path='/rent-a-car-in-Peshawar' component={rentacarPeshawar} />
          <Route exact path='/rent-a-car-in-Sahiwal' component={rentacarSahiwal} />
          <Route exact path='/rent-a-car-in-Sialkot' component={rentacarSialkot} />
          <Route exact path='/rent-a-car-in-Sukkur' component={rentacarSukkur} />
          <Route exact path='/rent-a-car-in-Gujrat' component={rentacarGujrat} />
          <Route exact path='/category/:categoriesName/all-products/:productName/:productId' component={HotelDetail} />

          {/* <Route exact path='/ab' component={SubCategoriesProduct} /> */}
          {/* <Route exact path='/categories/:categoriesName/all-products' component={SubCategoriesProduct} /> */}
          <Route exact path='/main-categories/:categoriesName' component={Categories} />
          {/* <Route exact path='/main-categories/:categoriesName' component={Categories} /> */}
          <Route exact path='/main-categories-car-rental/:categoriesName' component={Categories1} />
          <Route exact path='/categories/:categoriesName/all-products' component={SubCategoriesProduct2} />
          <Route exact path='/categories/:categoriesName/:categoriesName1/all-products' component={SubCategoriesProduct1} />
          <Route exact path='/categories/:categoriesName/:categoriesName1/:categoriesName2/all-products' component={SubCategoriesProduct} />
          <Route exact path='/categories/:categoriesName/:categoriesName1/:categoriesName2/all-products/:productName/:productId' component={SubCategoriesProductDetails} />
          <Route exact path='/categories/:categoriesName/:categoriesName1/all-products/:productName/:productId' component={SubCategoriesProductDetails2} />
          <Route exact path='/categories/:categoriesName/all-products/:productName/:productId' component={SubCategoriesProductDetails1} />
          {/* <Route exact path='/main-categories/:categoriesName/all-products/:productName/:productId' component={submain} /> */}
          <Route exact path='/main-categories/:categoriesName/:categoriesName1/:categoriesName2/all-products/:productName/:productId' component={SizeProductDetail} />
          <Route exact path='/categories-monthly/:categoriesName/all-products/:productName/:productId' component={rentacarmontly} />

          <Route path='*' component={Error} />

        </Switch>

        <Footer />

      </Router>
    </div>
  );
}

export default App;









