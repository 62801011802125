import React, { useState , useEffect } from 'react';
import {Link ,useHistory} from "react-router-dom"
// import FacebookIcon from '@material-ui/icons/Facebook';
// import InstagramIcon from '@material-ui/icons/Instagram';
// import TwitterIcon from '@material-ui/icons/Twitter';
// import YouTubeIcon from '@material-ui/icons/YouTube';
// import LinkedInIcon from '@material-ui/icons/LinkedIn';
// import SendIcon from '@material-ui/icons/Send';
// import LocationOnIcon from '@material-ui/icons/LocationOn';
// import PhoneForwardedIcon from '@material-ui/icons/PhoneForwarded';
// import { Flare } from '@material-ui/icons';
// import KeyboardArrowRightSharpIcon from '@material-ui/icons/KeyboardArrowRightSharp';
import logo1 from '../assets/images/Untitled design_20241011_203238_0000 (1).png';
import payment from '../asset1/images/payments.png';

// import FacebookIcon from '@mui/icons-material/Facebook';
  const Blogs =(props)=> {
    const [AllData , setAllData] = useState([])
    useEffect(() =>{

    fetch("https://pak-dream-admin-backs.vercel.app/AllCreatBLog",{
            method: "GET",
             headers :  {
             "Content-Type" : "application/json" , 
         } ,
        })
        .then(res3=>res3.json())
        .then(res4=>{
            let dat = []
            let dat1 = []
            res4.map((res55,c)=>{
                dat.push(res55)
                dat1.push(0)

     
            })
                        console.log(dat)
            let data = dat.sort((a, b) => {
                return a.sort - b.sort;
            });
            setAllData(data)

        })
      },[])

  //  history = useHistory();
  
        return (
            <>
    

<div class="container">
<div class="row">
  {/* <div class="leftcolumn"> */}
    {AllData.map((res,i)=>{
        return(
            <div class="col-12 col-lg-4 col-sm-12 card1">
            {/* <h5>Title description, Dec 7, 2017</h5> */}
            <div class="fakeimg">
                <img src={res.Image} alt="" style={{height:"300px"}}/>
            </div>
            <h2 style={{fontSize:"24px",padding:"10px 50px 10px 20px",width:"90%"}}>{res.main} ... </h2>
            {/* <p>Some text..</p> */}
                    <p style={{overflow:"hidden",maxHeight:"100px",maxWidth:"90%"}}> {res.text &&res.text ?<div dangerouslySetInnerHTML={{__html: res.text}} />:""}</p> 
                  <br />
                <center>
                        <span className="shadow-none" style={{borderRadius:"15px",maxWidth:"150px",color:"white",background :"rgb(87 1 6)",padding :"10px 15px",color  :"white",fontSize:"12px",fontWeight:"bold"}} onClick={()=>props.history.push(`/blog/${res.main.toLowerCase().trim().split(/[\s,\t,\n]+/).join('-')}`)}>Read More</span>
                    </center>

            </div>

        )
    })

    }
    {/* <div class="card1">
      <h2>TITLE HEADING</h2>
      <h5>Title description, Sep 2, 2017</h5>
      <div class="fakeimg" style={{height:"200px"}}>Image</div>
      <p>Some text..</p>
    </div> */}
  </div>
  {/* <div class="rightcolumn">
    <div class="card1">
      <h2>About Me</h2>
      <div class="fakeimg" style={{height:"100px"}}>Image</div>
      <p>Some text about me in culpa qui officia deserunt mollit anim..</p>
    </div>
    <div class="card1">
      <h3>Popular Post</h3>
      <div class="fakeimg">Image</div><br/>
      <div class="fakeimg">Image</div><br/>
      <div class="fakeimg">Image</div>
    </div>
    <div class="card1">
      <h3>Follow Me</h3>
      <p>Some text..</p>
    </div>
  </div> */}
{/* </div/> */}
</div>


            </>
        )
    
}

export default Blogs;