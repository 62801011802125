import React from 'react';
import {Link ,useHistory} from "react-router-dom"



const Contact = (props)=>  {
   


        return (
            <> 

          




<div className='container' style={{margin : "30px 10px"}}>
            <div className='row'  >
            <div className="col-10 col-lg-10  col-sm-12" style={{margin  :"auto"}}>
             
<urlset>


<div>
  <Link to='/'> > Home</Link>
</div>
<br/>

<div>
  <Link to='/about'> > About</Link>
</div>
<br/>

<div>
  <Link to='/contact'> > Contact</Link>
</div>
<br/>

<div>
  <Link to='/company-query'> > Company Query</Link>
</div>
<br/>

<div>
  <Link to='/Corporate-Equiries'> > Corporate Enquiries</Link>
</div>
<br/>

<div>
  <Link to='/term-policy'> > Term Policy</Link>
</div>
<br/>
<div>
  <Link to='/client'> > Our Client</Link>
</div>
<br/>

{/* <div>
  <Link to='/Shipping'> > Shipping</Link>
</div>
<br/> */}


<div>
  <Link to='/tourism'> > Tourism</Link>
</div>
<br/>

{/* <div>
  <Link to='/product/new-arrival'> > New Arrival</Link>
</div>
<br/> */}

{/* <div>
  <Link to='/product/recommended-product'> > Recommended Product</Link>
</div>
<br/>

<div>
  <Link to='/product/expert-pick'> > Expert Pick</Link>
</div>
<br/>

<div>
  <Link to='/shop-all'> > Shop All</Link>
</div>
<br/> */}

<div>
  <Link to='/privacy'> > Privacy Policy</Link>
</div>
<br/>

<div>
  <Link to='/sitemap'> > Sitemap</Link>
</div>
<br/>

{/* <div>
  <Link to='/favorite'> > Favorite</Link>
</div>
<br/> */}

<div>
  <Link to='/whislist'> > Wishlist</Link>
</div>
<br/>

<div>
  <Link to='/thankyou'> > Thank You</Link>
</div>
<br/>

{/* <div>
  <Link to='/consign'> > Consign</Link>
</div>
<br/> */}

<div>
  <Link to='/checkout'> > Checkout</Link>
</div>
<br/>

{/* <div>
  <Link to='/checkout2'> > Checkout 2</Link>
</div>
<br/> */}

<div>
  <Link to='/FAQ'> > FAQ</Link>
</div>
<br/>

{/* <div>
  <Link to='/chat'> > Chat</Link>
</div>
<br/> */}

<div>
  <Link to='/cart'> > Cart</Link>
</div>
<br/>
<div>
  <Link to='/Blogs'> > Blogs</Link>
</div>
<br/>

{/* <div>
  <Link to='/all-shop'> > All Shop</Link>
</div>
<br/> */}


{/* <div>
  <Link to='/track'> > Track</Link>
</div>
<br/> */}



<div>
  <Link to='/search/product-item'> > Search Product Item</Link>
</div>
<br/>







<p style={{color:"grey",fontSize:"16px",fontWeight:"bold"}}>Blogs</p>
<div style={{paddingLeft:"30px"}}>

{/* 
<div>
<Link to='/bullet-cars'> > Bullet Cars</Link>
</div>
<br/> */}

</div>

<br/>

<p style={{color:"grey",fontSize:"16px",fontWeight:"bold"}}>Vehicles</p>
<div style={{paddingLeft:"30px"}}>


<div>
  <Link to='/bullet-cars'> > Bullet Cars</Link>
</div>
<br/>

<div>
  <Link to='/luxury-cars'> > Luxury Cars</Link>
</div>
<br/>

<div>
  <Link to='/standard-cars'> > Standard Cars</Link>
</div>
<br/>

<div>
  <Link to='/suv-cars'> > SUV Cars</Link>
</div>
<br/>

<div>
  <Link to='/budget-cars'> > Budget Cars</Link>
</div>
<br/>

<div>
  <Link to='/coasters-cars'> > Coasters Cars</Link>
</div>
<br/>

<div>
  <Link to='/one-way-cars'> > One Way Cars</Link>
</div>
<br/>

<div>
  <Link to='/monthly-cars'> > Monthly Cars</Link>
</div>
<br/>
</div>















   <p style={{color:"grey",fontSize:"16px",fontWeight:"bold"}}>Pages</p>
<div style={{paddingLeft:"30px"}}>

<div>
  <Link to='/bullet-proof-vehicles-at-car-rentals-karachi-pakistan'> > Bullet Proof Vehicles at Car Rentals Karachi, Pakistan</Link>
</div>
<br/>

<div>
  <Link to='/rent-a-car-karachi-car-rental-service-with-driver-pakistan'> > Rent a Car Karachi - Car Rental Service with Driver</Link>
</div>
<br/>

<div>
  <Link to='/rent-a-car-rates-in-karachi'> > Rent a Car Rates in Karachi</Link>
</div>
<br/>

<div>
  <Link to='/cheap-car-hire-in-karachi-pakistan'> > Cheap Car Hire in Karachi, Pakistan</Link>
</div>
<br/>

<div>
  <Link to='/rent-car-services-in-karachi'> > Rent Car Services in Karachi</Link>
</div>
<br/>

<div>
  <Link to='/rent-a-car-in-lahore-islamabad-karachi'> > Rent a Car in Lahore, Islamabad, Karachi</Link>
</div>
<br/>

<div>
  <Link to='/providing-quality-car-rental-services'> > Providing Quality Car Rental Services</Link>
</div>
<br/>

<div>
  <Link to='/revo-car-rental-in-karachi'> > Revo Car Rental in Karachi</Link>
</div>
<br/>

<div>
  <Link to='/best-car-rental-rates-karachi'> > Best Car Rental Rates Karachi</Link>
</div>
<br/>

<div>
  <Link to='/karachi-rent-a-car-home-car'> > Karachi Rent a Car Home</Link>
</div>
<br/>

<div>
  <Link to='/rent-a-land-cruiser-v8-in-islamabad-rawalpindi'> > Rent a Land Cruiser V8 in Islamabad, Rawalpindi</Link>
</div>
<br/>

<div>
  <Link to='/audi-a3-for-rent-in-islamabad-rawalpindi-best-rates'> > Audi A3 for Rent in Islamabad, Rawalpindi - Best Rates</Link>
</div>
<br/>

<div>
  <Link to='/rent-a-audi-a5-search-a-car-car-rental-services'> > Rent a Audi A5 - Search a Car Rental Services</Link>
</div>
<br/>

<div>
  <Link to='/royal-rent-a-car-in-karachi-3000prk-per-day'> > Royal Rent a Car in Karachi - 3000 PKR per Day</Link>
</div>
<br/>

<div>
  <Link to='/karachi-tours-rent-a-car-gulshan-e-iqbal'> > Karachi Tours - Rent a Car Gulshan-e-Iqbal</Link>
</div>
<br/>

<div>
  <Link to='/rent-a-car-dha-rent-a-car-lahore-hire-luxury-car-rental'> > Rent a Car DHA - Rent a Car Lahore - Hire Luxury Car Rental</Link>
</div>
<br/>

<div>
  <Link to='/can-i-rent-a-car-for-just-a-few-hours'> > Can I Rent a Car for Just a Few Hours?</Link>
</div>
<br/>

<div>
  <Link to='/car-rental-rent-a-car-quickly-and-easy-in-karachi'> > Car Rental - Rent a Car Quickly and Easily in Karachi</Link>
</div>
<br/>

<div>
  <Link to='/hodivy-car-rental-choose-the-rate-thats-right-for-you'> > Hodivy Car Rental - Choose the Rate That's Right for You</Link>
</div>
<br/>

<div>
  <Link to='https:// www.pakdreamrentacar.com/book-hodivy-car-rental-save-upto-200-off-in-karachi'> > Book Hodivy Car Rental - Save Upto 200 Off in Karachi</Link>
</div>
<br/>

<div>
  <Link to='/are-you-looking-for-monthly-car-for-rent-in-islamabad'> > Are You Looking for Monthly Car for Rent in Islamabad?</Link>
</div>
<br/>

<div>
  <Link to='/rent-a-car-karachi-provides-an-expert-vehicle-maintenance-team'> > Rent a Car Karachi - Provides an Expert Vehicle Maintenance Team</Link>
</div>
<br/>

<div>
  <Link to='/rent-a-car-rates-in-karachi-with-driver-online-car-rental'> > Rent a Car Rates in Karachi with Driver - Online Car Rental</Link>
</div>
<br/>

<div>
  <Link to='/karachi-super-rent-a-car-all-over-pakistan'> > Karachi Super Rent a Car - All Over Pakistan</Link>
</div>
<br/>

<div>
  <Link to='/wedding-car-rental-in-karachi'> > Wedding Car Rental in Karachi</Link>
</div>
<br/>

<div>
  <Link to='/rent-a-car-for-wedding-car-rental-services'> > Rent a Car for Wedding - Car Rental Services</Link>
</div>
<br/>

<div>
  <Link to='/wedding-rent-a-car-car-rental-pakistan'> > Wedding Rent a Car - Car Rental Pakistan</Link>
</div>
<br/>

<div>
  <Link to='/best-rent-a-car-karachi-book-car-for-any-travel-purpose'> > Best Rent a Car Karachi - Book Car for Any Travel Purpose</Link>
</div>
<br/>

<div>
  <Link to='/cheap-premium-car-rental-in-karachi'> > Cheap Premium Car Rental in Karachi</Link>
</div>
<br/>

<div>
  <Link to='/luxury-and-prestige-car-rental-in-karachi'> > Luxury and Prestige Car Rental in Karachi</Link>
</div>
<br/>

<div>
  <Link to='/rent-a-car-karachi-luxury-car-rental-services'> > Rent a Car Karachi - Luxury Car Rental Services</Link>
</div>
<br/>

<div>
  <Link to='/premium-car-rental-karachi-top-deals-worldwide'> > Premium Car Rental Karachi - Top Deals Worldwide</Link>
</div>
<br/>

<div>
  <Link to='/searching-for-a-car-to-rent-with-no-deposit-near-you'> > Searching for a Car to Rent with No Deposit Near You</Link>
</div>
<br/>

<div>
  <Link to='/book-hassle-free-car-rentals'> > Book Hassle-Free Car Rentals</Link>
</div>
<br/>

<div>
  <Link to='/get-your-next-car-booking-with-pak-dream-at-500-pkr-off'> > Get Your Next Car Booking with Pak Dream at 500 PKR Off</Link>
</div>
<br/>

<div>
  <Link to='/car-rental-in-karachi-pakistan'> > Car Rental in Karachi, Pakistan</Link>
</div>
<br/>

<div>
  <Link to='/rent-a-car-in-Airport-Pick-&-Drop'> > Rent a Car - Airport Pick & Drop</Link>
</div>
<br/>

<div>
  <Link to='/rent-a-car-in-Wedding-Rent-A-Car'> > Rent a Car - Wedding Rent A Car</Link>
</div>
<br/>

<div>
  <Link to='/rent-a-car-in-Karachi-Pakistan'> > Rent a Car in Karachi, Pakistan</Link>
</div>
<br/>

<div>
  <Link to='/rent-a-car-in-Bufferzone'> > Rent a Car in Bufferzone</Link>
</div>
<br/>

<div>
  <Link to='/rent-a-car-in-Tariq-Road'> > Rent a Car in Tariq Road</Link>
</div>
<br/>

<div>
  <Link to='/rent-a-car-in-Baldia-Town'> > Rent a Car in Baldia Town</Link>
</div>
<br/>

<div>
  <Link to='/rent-a-car-in-Sindhi-Muslim-Society'> > Rent a Car in Sindhi Muslim Society</Link>
</div>
<br/>

<div>
  <Link to='/rent-a-car-in-Malir-Cantt'> > Rent a Car in Malir Cantt</Link>
</div>
<br/>

<div>
  <Link to='/rent-a-car-in-Korangi-Industrial-Area'> > Rent a Car in Korangi Industrial Area</Link>
</div>
<br/>

<div>
  <Link to='/rent-a-car-in-Bahria-Town-Karachi'> > Rent a Car in Bahria Town Karachi</Link>
</div>
<br/>

<div>
  <Link to='/rent-a-car-in-Landhi-Industrial-Area'> > Rent a Car in Landhi Industrial Area</Link>
</div>
<br/>



      <div>
  <Link to='/one-way-drop-in-karachi-to-islamabad'> > One Way Drop in Karachi to Islamabad</Link>
</div>
<br/>

<div>
  <Link to='/one-way-drop-in-karachi-to-multan'> > One Way Drop in Karachi to Multan</Link>
</div>
<br/>

<div>
  <Link to='/one-way-drop-in-karachi-to-lahore'> > One Way Drop in Karachi to Lahore</Link>
</div>
<br/>

<div>
  <Link to='/one-way-drop-in-karachi-to-faisalabad'> > One Way Drop in Karachi to Faisalabad</Link>
</div>
<br/>



<div>
  <Link to='/rent-a-car-in-badshahi-mosque-lahore'> > Rent a Car in Badshahi Mosque, Lahore</Link>
</div>
<br/>

<div>
  <Link to='/rent-a-car-in-shalimar-bagh-lahore'> > Rent a Car in Shalimar Bagh, Lahore</Link>
</div>
<br/>

<div>
  <Link to='/rent-a-car-in-tomb-of-jahangir-lahore'> > Rent a Car in Tomb of Jahangir, Lahore</Link>
</div>
<br/>
<div>
  <Link to='/rent-a-car-in-nooriabad'> > Rent a Car in Nooriabad</Link>
</div>
<br/>

<div>
  <Link to='/rent-a-car-in-minar-e-pakistan-lahore'> > Rent a Car in Minar-e-Pakistan, Lahore</Link>
</div>
<br/>

<div>
  <Link to='/rent-a-car-in-lahore-museum'> > Rent a Car in Lahore Museum</Link>
</div>
<br/>

<div>
  <Link to='/rent-a-car-in-wazir-khan-mosque-lahore'> > Rent a Car in Wazir Khan Mosque, Lahore</Link>
</div>
<br/>

<div>
  <Link to='/rent-a-car-in-delhi-gate-lahore'> > Rent a Car in Delhi Gate, Lahore</Link>
</div>
<br/>

<div>
  <Link to='/rent-a-car-in-anarkali-bazar-lahore'> > Rent a Car in Anarkali Bazar, Lahore</Link>
</div>
<br/>

<div>
  <Link to='/rent-a-car-in-liberty-market-lahore'> > Rent a Car in Liberty Market, Lahore</Link>
</div>
<br/>

<div>
  <Link to='/rent-a-car-in-pearl-continental-hotel-lahore'> > Rent a Car in Pearl Continental Hotel, Lahore</Link>
</div>
<br/>



<div>
  <Link to='/one-way-drop-in-karachi-to-islamabad'> > One Way Drop in Karachi to Islamabad</Link>
</div>
<br/>

<div>
  <Link to='/rent-a-car-in-Larkana'> > Rent a Car in Larkana</Link>
</div>
<br/>

<div>
  <Link to='/rent-a-car-in-Gulshan-e-Hadeed'> > Rent a Car in Gulshan-e-Hadeed</Link>
</div>
<br/>

<div>
  <Link to='/rent-a-car-in-Gulshan-e-Maymar'> > Rent a Car in Gulshan-e-Maymar</Link>
</div>
<br/>

<div>
  <Link to='/rent-a-car-in-Rahim-Yaar-Khan'> > Rent a Car in Rahim Yaar Khan</Link>
</div>
<br/>

<div>
  <Link to='/rent-a-car-in-Defence'> > Rent a Car in Defence</Link>
</div>
<br/>

<div>
  <Link to='/rent-a-car-in-Pakistan'> > Rent a Car in Pakistan</Link>
</div>
<br/>

<div>
  <Link to='/rent-a-car-in-Dadu'> > Rent a Car in Dadu</Link>
</div>
<br/>

<div>
  <Link to='/rent-a-car-in-Shahdadkot'> > Rent a Car in Shahdadkot</Link>
</div>
<br/>

<div>
  <Link to='/rent-a-car-in-Clifton'> > Rent a Car in Clifton</Link>
</div>
<br/>

<div>
  <Link to='/rent-a-car-in-Port-Qasim'> > Rent a Car in Port Qasim</Link>
</div>
<br/>

<div>
  <Link to='/rent-a-car-in-Nizamabad'> > Rent a Car in Nizamabad</Link>
</div>
<br/>

<div>
  <Link to='/rent-a-car-in-North-Nizamabad'> > Rent a Car in North Nizamabad</Link>
</div>
<br/>

<div>
  <Link to='/rent-a-car-in-Gulshan-e-Iqbal'> > Rent a Car in Gulshan-e-Iqbal</Link>
</div>
<br/>

<div>
  <Link to='/rent-a-car-in-Gulistan-e-Johar'> > Rent a Car in Gulistan-e-Johar</Link>
</div>
<br/>

<div>
  <Link to='/rent-a-car-in-karachi'> > Rent a Car in Karachi</Link>
</div>
<br/>

<div>
  <Link to='/rent-a-car-in-Abbottabad'> > Rent a Car in Abbottabad</Link>
</div>
<br/>

<div>
  <Link to='/rent-a-car-in-Faisalabad'> > Rent a Car in Faisalabad</Link>
</div>
<br/>

<div>
  <Link to='/rent-a-car-in-Gujranwala'> > Rent a Car in Gujranwala</Link>
</div>
<br/>

<div>
  <Link to='/rent-a-car-in-Islamabad'> > Rent a Car in Islamabad</Link>
</div>
<br/>

<div>
  <Link to='/rent-a-car-in-Jhelum'> > Rent a Car in Jhelum</Link>
</div>
<br/>

<div>
  <Link to='/rent-a-car-in-Hyderabad'> > Rent a Car in Hyderabad</Link>
</div>
<br/>

<div>
  <Link to='/rent-a-car-in-Nawabshah'> > Rent a Car in Nawabshah</Link>
</div>
<br/>

<div>
  <Link to='/rent-a-car-in-Lahore'> > Rent a Car in Lahore</Link>
</div>
<br/>

<div>
  <Link to='/rent-a-car-in-Multan'> > Rent a Car in Multan</Link>
</div>
<br/>

<div>
  <Link to='/rent-a-car-in-Peshawar'> > Rent a Car in Peshawar</Link>
</div>
<br/>

<div>
  <Link to='/rent-a-car-in-Sahiwal'> > Rent a Car in Sahiwal</Link>
</div>
<br/>

<div>
  <Link to='/rent-a-car-in-Sialkot'> > Rent a Car in Sialkot</Link>
</div>
<br/>

<div>
  <Link to='/rent-a-car-in-Sukkur'> > Rent a Car in Sukkur</Link>
</div>
<br/>

<div>
  <Link to='/rent-a-car-in-Gujrat'> > Rent a Car in Gujrat</Link>
</div>
<br/>





 
</div>

















</urlset>

</div>
            </div>
            </div>


            </>
           
        )
    
}


export default Contact;







