import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import swal from 'sweetalert';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';




const Contact = (props)=>  {
    const [name, setname] = useState("") 
    const [lname, setlname] = useState("") 
    const [email, setemail] = useState("") 
    const [subject, setsubject] = useState("") 
    const [Message, setMessage] = useState("") 
    const [data, setdata] = useState([]) 
    const [data1, setdata1] = useState([]) 
    const [fg , setfg] = useState(true)
    const [fvalue , setfvalue] = useState([])


   useEffect(()=>{
    window.scrollTo(0, 0)

    fetch("https://d-luxe-housebackend.vercel.app/FAQ",{
        method: "GET",
             headers :  {
             "Content-Type" : "application/json" , 
         } ,
        })
        .then(res3=>res3.json())
        .then(res4=>{
            let dat1 = []
            res4.map((res55,c)=>{
                dat1.push(0)
            })
            setfvalue(dat1)
               setdata(res4)
               data.map((res,i)=>{
                data1[i] = res.text
               })

            console.log(res4)
          })
          return () => {
            document.getElementById("get1").classList.remove("is-active");
            document.getElementById("get2").style.display = "none";
          }
        },[])

      
        
const setChangerItemSize = (e)=>{

    if (fg) setfg(false)
    else setfg(true)

    for (let i = 0; i < 45; i++) {
      if(i === e){
        if(fvalue[e] === 0){
            fvalue[e] = 1
            // let f = document.getElementById("changeIcon"+i)
             document.getElementById("changeIcon1"+i).style.transform = "rotate(180deg)"
            
        }
        else {
            fvalue[e] = 0
            // let v = document.getElementById("changeIcon"+i)
             document.getElementById("changeIcon1"+i).style.transform = "rotate(0deg)"
            // v.innerHTML= ""
         }
      }
   
    }
  
  setfvalue(fvalue)
    if (fg) setfg(false)
    else setfg(true)
    
}
    



        return (
            <div>
                {/* <div className="bg-light py-3">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 mb-0"><Link to="/">Home</Link> <span className="mx-2 mb-0">/</span> <strong className="text-black">Contact</strong></div>
                        </div>
                    </div>
                </div> */}

                <div className="site-section">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <center>
                                <h2 className="h3 mb-5 mt-5 text-black">HOW WE CAN HELP YOU ?</h2>

                                </center>
                            </div>
                            {/* <div className="col-md-12"> */}

                            {/* <nav>
                                <div class="nav nav-tabs" id="nav-tab" role="tablist">
                                    <button class="nav-link active" id="nav-home-tab" data-bs-toggle="tab" data-bs-target="#nav-home" type="button" role="tab" aria-controls="nav-home" aria-selected="true">Home</button>
                                    <button class="nav-link" id="nav-profile-tab" data-bs-toggle="tab" data-bs-target="#nav-profile" type="button" role="tab" aria-controls="nav-profile" aria-selected="false">Profile</button>
                                    <button class="nav-link" id="nav-contact-tab" data-bs-toggle="tab" data-bs-target="#nav-contact" type="button" role="tab" aria-controls="nav-contact" aria-selected="false">Contact</button>
                                </div>
                                </nav>
                                <div class="tab-content" id="nav-tabContent">
                                <div class="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">...1</div>
                                <div class="tab-pane fade" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">...2</div>
                                <div class="tab-pane fade" id="nav-contact" role="tabpanel" aria-labelledby="nav-contact-tab">...3</div>
                                </div>
*/}
                           
              <div className='container' style={{margin : "30px 10px"}}>
              <div className='row'  >
            <div className="col-10 col-lg-10  col-sm-12" style={{margin  :"auto"}}>
             
              <div class="faq-header">Frequently Asked Questions</div>

<div class="faq-content">
  <div class="faq-question">
    <input id="q1" type="checkbox" class="panel"/>
    <div class="plus">+</div>
    <label for="q1" class="panel-title">Who can renting a car?</label>
    <div class="panel-content">The car with driver can be rented by anyone above the age of 18. If you are a resident of Pakistan, you need to have a valid driving license along with NIC. If you are on a tourist visa then you need to have a valid home country license OR an international driving license and valid passport with visa stamp.

</div>
  </div>
  
  <div class="faq-question">
    <input id="q2" type="checkbox" class="panel"/>
    <div class="plus">+</div>
    <label for="q2" class="panel-title">Can I renting a car with driver ?</label>
    <div class="panel-content">Yes, you may book any car available on our website, with driver.

</div>
  </div>
  
  <div class="faq-question">
    <input id="q3" type="checkbox" class="panel"/>
    <div class="plus">+</div>
    <label for="q3" class="panel-title">How can I pay the renting?</label>
    <div class="panel-content">We accept cash and bank transfers at the moment.

</div>
  </div>
  <div class="faq-question">
    <input id="q4" type="checkbox" class="panel"/>
    <div class="plus">+</div>
    <label for="q4" class="panel-title">Do I need to pay any security deposit?</label>
    <div class="panel-content">No, security deposit is not required.



</div>
  </div>
  <div class="faq-question">
    <input id="q5" type="checkbox" class="panel"/>
    <div class="plus">+</div>
    <label for="q5" class="panel-title">What happens if an accident occurs?</label>
    <div class="panel-content">All our cars are insured, the bigger damages are covered by the insurance. However, the debt charged by the insurance company shall be paid by the customer. Furthermore, if the car stays at the workshop for repairs / damage caused by the accident, the rent for that period shall also be paid by the customer.



</div>
  </div>
  <div class="faq-question">
    <input id="q6" type="checkbox" class="panel"/>
    <div class="plus">+</div>
    <label for="q6" class="panel-title">
    What is your fuel policy?</label>
    <div class="panel-content">Customers are responsible for paying for their own fuel consumption. Each car is provided with a level of fuel and customers have to return the vehicle with the same level of fuel as they were given. We also provide an option to the customers, where they can pay for the number of kilometers done by the car at the specified rates for each car category, starting from the point the car leaves the office / Car Owner’s / Driver’s location.



</div>
  </div>
</div>

</div>
            </div>
            </div>






                            {/* <div className="col-md-12 ml-auto">
                                {data.map((res,i)=>{
                                    return (
                                        <div className="p-4 border mb-3">
                                    <span className="d-block text-primary h6 text-uppercase">{res.main}</span>
                                    <p className="mb-0">
                                        {
         
                                              res.text.split("\n").map((res4)=>{
                                                    return (
                                                        <>
                                                        {res4} <br/>
                                                        </>
                                                    )
                                        })
                                              
                                            
                                        }
                                    </p>
                                </div>
                                    )
                                })

                                }
                               
                            </div> */}

                            </div> 



                        {/* </div> */}
                    </div>
                </div>
            </div>
        )
    
}


export default Contact;